import React, { useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import actions from "../../actions";
import { getUsersReport } from "../../apis";
import * as XLSX from "xlsx"; // Import the xlsx library
import { type } from "@testing-library/user-event/dist/type";

const Reports = ({ logoutRequest, startLoadingRequest, stopLoadingRequest }) => {
  const [reportType, setReportType] = useState("users-report");
  const [days, setDays] = useState("7");

  // Helper function to flatten nested objects
  const flattenObject = (obj, prefix = "") => {
    let result = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const newKey = prefix ? `${prefix}.${key}` : key;
        if (typeof obj[key] === "object" && obj[key] !== null) {
          Object.assign(result, flattenObject(obj[key], newKey)); // Recursively flatten the nested object
        } else {
          result[newKey] = obj[key];
        }
      }
    }
    return result;
  };

  const mapHeaders = (reportType) => {
    const headersMap = {
      "users-report": {
        firstName: "First Name",
        lastName: "Last Name",
        gender: "Gender",
        "baseSalon.name": "Base Salon Name", // Adjust for nested object
        isConsentAllowed: "Consent Allowed",
        referredBy: "Referred By",
        referralLevel: "Referral Level",
      },
      "banners-report": {
        id: "ID",
        caption: "Caption",
        title: "Title",
        createdAt: "Upload Date",
        goLiveDateTime: "Go Live Date & Time",
        isLive: "Is Live",
        "action.dateTime": "Last Action Date & Time",
        type: "Type",
      },
     "blogs-report": {    
        id: "ID",
        headline: "Headline",
        content: "Content",
        createdAt: "Upload Date",
        "category.name": "Category Name",
        "document.url": " Blog Image Url",
        readingTime: "Reading Time",
        isActive: "Is Active",
        publishDateTime: "Publish Date & Time",
        status: "Status",
        createdAt: "Created Date",
        updatedAt: "Updated Date",
      },
      "notifications-report": {
        notificationId: "Notification ID",
        message: "Message",
      },
    };

    return headersMap[reportType] || {}; // Return headers as an object for the given report type
  };

  const handleDownload = async () => {
    toast.success(`Download initiated for ${reportType} report of last ${days} days!`);
    
    try {
      const response = await getUsersReport(reportType, days);
      console.log(response)
      let data=[]
      if (reportType === "users-report") {
        data = response.data.data.users;
      } else if (reportType === "banners-report") {
        data = response.data.data.banners;
      }
      else if (reportType === "blogs-report") {
        data = response.data.data.blogs;
      }
       else {
        console.error(`Unsupported report type: ${reportType}`);
      }
      // const data = response.data.data.users; // Assuming this is the users data for "users-report"
    
      // Dynamically map headers based on report type
      const headers = mapHeaders(reportType);

      // Flatten each data row
      const flattenedData = data.map(item => flattenObject(item));

      // Get all keys from the flattened data
      const allKeys = new Set();
      flattenedData.forEach(item => {
        Object.keys(item).forEach(key => allKeys.add(key));
      });

      // Combine headers and backend keys to form the final list of headers
      const finalHeaders = Array.from(allKeys).map(key => {
        return {
          key: key,
          label: headers[key] || key // Use label from mapHeaders or fallback to the key
        };
      });

      // Map flattened data to match the headers
      const mappedData = flattenedData.map(item =>
        finalHeaders.reduce((acc, header) => {
          acc[header.label] = item[header.key];
          return acc;
        }, {})
      );

      console.log(mappedData);

      // Create a worksheet from the mapped data
      const ws = XLSX.utils.json_to_sheet(mappedData);

      // Set dynamic column widths
      const colWidths = finalHeaders.map(header => {
        // Find the max length of the header or the column data
        const maxLength = Math.max(
          header.label.length,
          ...mappedData.map(row => String(row[header.label]).length)
        );
        return { wpx: maxLength * 10 }; // Width based on content, multiplied by 10 for spacing
      });

      // Apply the dynamic column widths
      ws['!cols'] = colWidths;

      // Make headers bold by applying style
      finalHeaders.forEach((header, index) => {
        const cellAddress = { r: 0, c: index }; // Row 0 is the header row
        if (ws[cellAddress]) {
          ws[cellAddress].s = {
            font: { bold: true } // Set font to bold
          };
        }
      });

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, `${reportType.replace("-", " ").toUpperCase()} Report`);

      // Trigger the download
      XLSX.writeFile(wb, `${reportType}_${days}_days.xlsx`);
    } catch (error) {
      toast.error("Error while downloading the report.");
      console.error("Error downloading the report:", error);
    }
  };

  return (
    <div className="section">
      <h1>Reports</h1>
      <div className="wrapper">
        <div className="table-wrapper">
          <div className="table-header flex-start">
            {/* First Dropdown */}
            <div className="input-wrapper">
              <label htmlFor="reportType">Report Type:</label>
              <select
                id="reportType"
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
              >
                <option value="users-report">User</option>
                <option value="banners-report">Banner</option>
                <option value="blogs-report">Blogs</option>s
                <option value="notifications-report">Notifications</option>
              </select>
            </div>
            {/* Second Dropdown */}
            <div className="input-wrapper">
              <label htmlFor="days">Time Period:</label>
              <select
                id="days"
                value={days}
                onChange={(e) => setDays(e.target.value)}
              >
                <option value="0">Today</option>
                <option value="7">Last 7 days</option>
                <option value="30">Last 30 days</option>
                <option value="90">Last 90 days</option>
                <option value="365">Last 1 year</option>
              </select>
            </div>
            {/* Download Button */}
            <button
              onClick={handleDownload}
              className="btn-download"
              style={{
                backgroundColor: "#4CAF50", // Green background
                color: "#fff", // White text
                padding: "10px 20px",
                borderRadius: "5px",
                border: "none",
                cursor: "pointer",
                fontWeight: "bold",
              }}
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logoutRequest: actions.auth.logout,
      stopLoadingRequest: actions.loader.stopLoading,
      startLoadingRequest: actions.loader.startLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Reports);
